<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="shoppingEvents"
        :options.sync="pagination"
        :server-items-length="totalShoppingEvents"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1"
    >
      <template v-slot:item.occupancy="{ item }">
        <td class="text-xs-right">
          <v-chip
              :color="getColor(item.shoppingEventPosts, item.itemLimit)"
              dark
          >
            {{
              countOccupancy(item.shoppingEventPosts, item.itemLimit)
            }}
          </v-chip>

        </td>
      </template>
      <template v-slot:item.itemsInReview="{ item }">
        <td class="text-xs-right">
          {{
            countInReview(item.shoppingEventPosts)
          }}
        </td>
      </template>
      <template v-slot:item.mainFeedBanner="{ item }">
        <a v-bind:href="item.mainFeedBanner != null ? item.mainFeedBanner.filePath : null" target="_blank">
          <v-img
              v-if="item.mainFeedBanner != null && item.mainFeedBanner.filePath != null"
              :src="item.mainFeedBanner.filePath"
              contain
              max-height="150"
              max-width="150"
              @error="item.mainFeedBanner.filePath = 'media/error/imageNotFound.png'">
          </v-img>
        </a>
      </template>
      <template v-slot:item.beforeEventBanner="{ item }">
        <a v-bind:href="item.beforeEventBanner != null ? item.beforeEventBanner.filePath : null" target="_blank">
          <v-img
              v-if="item.beforeEventBanner != null && item.beforeEventBanner.filePath != null"
              :src="item.beforeEventBanner.filePath"
              contain
              max-height="150"
              max-width="150"
              @error="item.beforeEventBanner.filePath = 'media/error/imageNotFound.png'">
          </v-img>
        </a>
      </template>
      <template v-slot:item.duringEventBanner="{ item }">
        <a v-bind:href="item.duringEventBanner != null ? item.duringEventBanner.filePath : null" target="_blank">
          <v-img
              v-if="item.duringEventBanner != null && item.duringEventBanner.filePath != null"
              :src="item.duringEventBanner.filePath"
              contain
              max-height="150"
              max-width="150"
              @error="item.duringEventBanner.filePath = 'media/error/imageNotFound.png'">
          </v-img>
        </a>
      </template>
      <template v-slot:item.cities="{ item }">
        <v-chip
            class="ma-2"
            color="blue darken-1"
            text-color="white"
            v-if="item.allCities === true"
        >
          ALL CITIES
        </v-chip>

        {{ item.allCities === false && item.cities != null && item.cities.length > 0 ? item.cities[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.allCities === false && item.cities != null && item.cities.length > 1" small class="mr-2"
                    v-on="on"
            >mdi-dots-horizontal
            </v-icon
            >
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.cities" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.allCities="{ item }">
        <v-switch v-model="item.allCities" disabled></v-switch>
      </template>
      <template v-slot:item.start="{ item }">
        {{ parseDate(item.start) }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ parseDate(item.end) }}
      </template>
      <template v-slot:item.showBeforeStart="{ item }">
        {{ parseDate(item.showBeforeStart) }}
      </template>
      <template v-slot:item.search="{ item }">
        <v-avatar color="primary" size="26"
                  rounded>
          <router-link
              :to="{
              name: 'ShoppingEventPosts',
              params: { shoppingEventId: item.id }
            }"
          >
            <v-icon dark>mdi-details</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)" color="teal" size="26"
                  rounded>
          <router-link
              :to="{
              name: 'ShoppingEventEdit',
              params: { shoppingEventId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)" color="red darken-4"
                  size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-datetime-picker
                :label="$t('date.from')"
                v-model="dateFrom"
                time-format="HH:mm:ss"
            ></v-datetime-picker>
            <v-datetime-picker
                :label="$t('date.to')"
                v-model="dateTo"
                time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-autocomplete
                v-model="city"
                :items="userCities"
                :loading="citiesIsLoading"
                :search-input.sync="search"
                clearable
                item-text="name"
                item-value="id"
                label="City"
                @keyup.enter="page = 1;retrieveShoppingEvents();"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for
                    <strong>cities</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                        v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  retrieveShoppingEvents();
                "
              >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  clearSearch();
                "
              >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'ShoppingEventCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm"/>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import {mapGetters} from "vuex";

export default {
  name: "shopping-events",
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        {text: "Id", align: "start", sortable: false, value: "id"},
        {text: this.$i18n.t('common.donationGoal'), value: "donationGoalTitle", sortable: false},
        {text: this.$i18n.t('common.start'), value: "start", sortable: false},
        {text: this.$i18n.t('common.end'), value: "end", sortable: false},
        {text: this.$i18n.t('common.showBeforeStart'), value: "showBeforeStart", sortable: false},
        {text: this.$i18n.t('common.mainFeedBanner'), value: "mainFeedBanner", sortable: false},
        {text: this.$i18n.t('common.beforeEventBanner'), value: "beforeEventBanner", sortable: false},
        {text: this.$i18n.t('common.duringEventBanner'), value: "duringEventBanner", sortable: false},
        {text: this.$i18n.t('commonTable.cities'), value: "cities", sortable: false},
        {text: this.$i18n.t('common.occupancy'), value: "occupancy", sortable: false},
        {text: this.$i18n.t('common.inReviewItems'), value: "itemsInReview", sortable: false},
        {text: this.$i18n.t('common.sessionDurationSeconds'), value: "sessionDurationSeconds", sortable: false},
        {text: "", value: "search", width: "2%", sortable: false},
        {text: "", value: "clear", width: "2%", sortable: false},
        {text: "", value: "add", width: "2%", sortable: false}
      ]
    }
  },
  data() {
    return {
      dateFrom : null ,
      dateTo : null ,
      totalShoppingEvents: 0,
      shoppingEvents: [],
      loading: true,
      pagination: {},
      dialogDelete: false,
      itemToDelete: null,
      errors: [],
      userCities: [],
      search: "",
      citiesIsLoading: false,
      city : ""
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShoppingEvents();
      },
      deep: true
    }
  },
  created() {
    this.searchCities();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Shopping Events"}]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    countOccupancy(shoppingEventPosts, itemLimit) {
      const approvedPosts = shoppingEventPosts.filter((obj) => obj.status === 'APPROVED').length;
      return approvedPosts + "/" + itemLimit;
    },
    getColor (shoppingEventPosts, itemLimit) {
      const approvedPosts = shoppingEventPosts.filter((obj) => obj.status === 'APPROVED').length;
      if (approvedPosts > itemLimit) return 'red'
      else return 'green'
    },
    clearSearch() {
      this.city = "";
      this.dateFrom = null;
      this.dateTo = null;
    },
    countInReview(shoppingEventPosts) {
      return shoppingEventPosts.filter((obj) => obj.status === 'IN_REVIEW').length;
    },
    searchCities() {
      // Items have already been loaded
      if (this.userCities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
          .then(res => {
            this.userCities = res.data;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.citiesIsLoading = false));
    },
    parseDate(date) {
      return moment
          .utc(date)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },
    async delRecord(item) {
      if (
          await this.$refs.confirm.open(
              this.$i18n.t('common.confirm'),
              this.$i18n.t('pages.shoppingEvents.shoppingEventDeleteMessage') +
              item.id +
              " ?"
          )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteShoppingEvent(item.id);
    },
    deleteShoppingEvent(shoppingEventId) {
      this.loading = true;
      ApiService.delete(`api/shopping-event/${shoppingEventId}`)
          .then(response => {
            this.$log.debug("Shopping event deleted: ", response);
            this.retrieveShoppingEvents();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    getRequestParams(city, dateFrom, dateTo) {
      let params = {};

      if (city) {
        params["city"] = city;
      }

      if (dateFrom) {
        params["dateFrom"] = dateFrom;
      }

      if (dateTo) {
        params["dateTo"] = dateTo;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    retrieveShoppingEvents() {
      const params = this.getRequestParams(this.city, this.dateFrom, this.dateTo);
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shopping-event", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Promotions: ", response.data.content);
          this.shoppingEvents = response.data.content;
          this.totalShoppingEvents = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalShoppingEvents != null ? this.totalShoppingEvents : 0;
          this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Shopping events: " + title}
          ]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
